<template>
  <div>
    <b-alert show variant="light" class="alert alert-elevate">
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">{{ $t("ATTENDANCE.GUIDE") }}</div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title" class="kt-section__title-lg">
          <template v-slot:body>
            <b-form class="kt-form" @submit.stop.prevent="submitAttendance">
              <div
                role="alert"
                v-if="errors.length"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  <ul v-for="(error, i) in errors" :key="i">
                    <li>{{ error }}</li>
                  </ul>
                </div>
              </div>
              <div role="alert" v-if="message" class="alert alert-warning">
                <div class="alert-text">
                  {{ message }}
                </div>
              </div>
              <b-row v-else>
                <b-table
                  :fields="class_students_fields"
                  :items="class_students"
                  :busy="isBusy"
                  striped
                  bordered
                  head-variant="dark"
                  show-empty
                >
                  <template v-slot:cell(actions)="row">
                    <b-form-checkbox
                      size="lg"
                      value="1"
                      unchecked-value="0"
                      v-model="class_students[row.index].attendance"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:empty="scope">
                    <p class="text-center">
                      {{ $t("ATTENDANCE.NO_STUDENT_IN_CLASS") }}
                    </p>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                    </div>
                  </template>
                </b-table>
              </b-row>
              <b-row>
                <b-col
                  v-if="message == null"
                  class="kt-margin-t-10 col-lg-9 col-md-9 col-sm-6"
                >
                  <b-button
                    type="submit"
                    id="kt_submit"
                    class="btn btn-primary"
                  >
                    {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                    {{ $t("MENU.ATTENDANCE") }}
                  </b-button>
                </b-col>
                <b-col class="kt-margin-t-10 col-lg-3 col-md-3 col-sm-4">
                  <router-link
                    to="/lecturer/assigned-classes"
                    class="btn btn-primary"
                  >
                    <i class="flaticon2-right-arrow"></i>
                    {{ $t("GENERAL.BACK") }}
                  </router-link>
                </b-col>
              </b-row>
            </b-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      title: this.$t("MENU.ATTENDANCE"),
      isBusy: true,
      class_students_fields: [
        { number: this.$t("GENERAL.NUMBER") },
        { student_id: this.$t("PROFILE.ID") },
        { name: this.$t("PROFILE.NAME") },
        { father_name: this.$t("PROFILE.FATHER_NAME") },
        { actions: this.$t("ATTENDANCE.ATTENDANCE_STATE") }
      ],
      class_students: [],
      message: null
    };
  },
  components: {
    KTPortlet
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getClassStudents(id) {
      this.isBusy = true;
      this.errors = [];
      ApiService.get("lecturer/class-students/" + id)
        .then(({ data }) => {
          this.class_students = data.class_students;
          this.message = data.message;
          this.isBusy = false;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.isBusy = false;
        });
    },
    submitAttendance() {
      this.errors = [];
      let formData = this.class_students.map(item => {
        return {
          exam_mark_id: item.exam_mark_id,
          attendance: item.attendance
        };
      });
      let class_subject_id = this.$route.params.id;
      this.isBusy = true;
      ApiService.post("lecturer/class-students-attendance", {
        formData,
        class_subject_id
      })
        .then(result => {
          this.isBusy = false;
          this.$router.push({
            name: "lecturerAssignedClasses",
            params: { message: result.data.message }
          });
        })
        .catch(({ response }) => {
          this.isBusy = false;
          this.errors = response.data.error;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.ATTENDANCE"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.post("lecturer/class-information", { id: this.$route.params.id })
      .then(({ data }) => {
        this.title +=
          " " +
          this.$t("ATTENDANCE.TITLE", {
            class: data.class_name,
            subject: data.subject,
            time: data.class_time,
            semester: data.semester,
            faculty: data.faculty
          });
        this.getClassStudents(this.$route.params.id);
      })
      .catch(({ response }) => {
        if (response && (response.status === 403 || response.status === 422)) {
          this.$router.push({ name: "lecturerAssignedClasses" });
        }
      });
  }
};
</script>
